'use client'
import React, { useEffect, useState, type ReactNode } from "react"

import Cookies from 'js-cookie';
import { SiteConfig } from "@/config/site"
import { formatPhoneNumber } from "@/lib/utils"
import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"


interface PhoneNumberPropsIf {
  currentDealer: GetSingleDealerReturnType | null
  hideNumber?: boolean;
  numberFromServerComponent?: string | number | null
  classNameObj?: {container?:string, children?:string, number?:string}
  children?: ReactNode
}


export default function PhoneNumber({ currentDealer, hideNumber=false, classNameObj={container:'', children:'', number:''}, numberFromServerComponent, children}: PhoneNumberPropsIf) {
  const [phoneNumber, setPhoneNumber] = useState<string | number | null>(null)
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const phoneNumberFromTracker = Cookies.get(SiteConfig.cookies.phoneNumber);
    setPhoneNumber(phoneNumberFromTracker || numberFromServerComponent ||(currentDealer?.phone || null))

  }, [])


    return (
      <div>
        {mounted ? (
            phoneNumber &&
            <a href={`tel:${phoneNumber}`}>
              <div className={classNameObj?.container} >
                {children && <div className={classNameObj?.children}>{children}</div>}
                { !hideNumber && <span className={classNameObj?.number}>{formatPhoneNumber(phoneNumber)}</span>}
              </div>
            </a>
          )
          :
          <a href={`tel:${numberFromServerComponent}`}>
            <div className={classNameObj?.container} >
              {children && <div className={classNameObj?.children}>{children}</div>}
              { !hideNumber && <span className={classNameObj?.number}>{numberFromServerComponent ? formatPhoneNumber(numberFromServerComponent) : 'loading...'}</span>}
            </div>
          </a>
        }
      </div>

    )
  }
