import { cache as react_cache } from "react"
import { unstable_cache } from "next/cache"
import { ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

import { env } from "@/env.mjs"

import { GetSingleDealerReturnType } from "./server-api/dealers-types"

type NextCacheType = typeof unstable_cache

// export function cache(
//   ...inputs: Parameters<NextCacheType>
// ): ReturnType<NextCacheType> {
//   return react_cache(unstable_cache(...inputs))
// }

export const cache = unstable_cache

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(input: string | number): string {
  const date = new Date(input)
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

export function absoluteUrl(path: string) {
  return `${env.NEXT_PUBLIC_APP_URL}${path}`
}

export function formatPhoneNumber(phoneNumberString: string | number | null) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? "" : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}

interface DealerHoursProps {
  hrs_mon: string | null
  hrs_tues: string | null
  hrs_weds: string | null
  hrs_thurs: string | null
  hrs_fri: string | null
  hrs_sat: string | null
  hrs_sun: string | null
}

export const getDealerHours = ({
  hrs_mon,
  hrs_tues,
  hrs_weds,
  hrs_thurs,
  hrs_fri,
  hrs_sat,
  hrs_sun,
}: DealerHoursProps) => {
  return [hrs_sun, hrs_mon, hrs_tues, hrs_weds, hrs_thurs, hrs_fri, hrs_sat]
}

getDealerHours.days = ({
  hrs_mon,
  hrs_tues,
  hrs_weds,
  hrs_thurs,
  hrs_fri,
  hrs_sat,
  hrs_sun,
}: DealerHoursProps) => {
  return [
    { day: "Sunday", hours: hrs_sun },
    { day: "Monday", hours: hrs_mon },
    { day: "Tuesday", hours: hrs_tues },
    { day: "Wednesday", hours: hrs_weds },
    { day: "Thursday", hours: hrs_thurs },
    { day: "Friday", hours: hrs_fri },
    { day: "Saturday", hours: hrs_sat },
  ]
}

export const getTodaysHours = (dealer: GetSingleDealerReturnType) => {
  const now = new Date()
  const days = dealer ? getDealerHours(dealer) : ""
  const today = days[now.getDay()]
  const hours = dealer ? today : ""
  const isOpen = dealer && hours != "Closed"
  const isByAppointment = dealer && hours == "By Appointment"
  const dailyHours = isOpen && !isByAppointment ? `${hours}` : `${hours}`

  return {
    isOpen,
    dailyHours,
  }
}

export function metersToMiles(meters: number): number {
  const METERS_PER_MILE = 1609.34
  return meters / METERS_PER_MILE
}

export function extractDealerLatLng(str: string | null) {
  if (str === null) return null
  // Remove unwanted characters
  str = str.replace(/"/g, "").trim()

  // Match the different formats
  const regex = /(-?\d+\.\d+)[,; ]\s*(-?\d+\.\d+)/

  const match = str.match(regex)

  if (match) {
    return {
      lat: parseFloat(match[1]),
      lng: parseFloat(match[2]),
    }
  } else {
    return null
  }
}

export function createObjectFromArrayOfObjects(array: any[], keyBy: string){
  if(array.length <1) return null
  return array.reduce((newObj, element) => {
    newObj[element[keyBy]] = { ...element }
    return newObj
  }, {})
}

export function titleCase(str:string) {
  if (!str) {
    return "";
  }
  return str.toLowerCase().split(' ').map(function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}
