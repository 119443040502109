"use client"

import { SiteConfig } from "@/config/site"
import { GetAllDealersReturnType } from "@/lib/server-api/dealers-types"
import { cn, formatPhoneNumber } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import DealerMapAllLazy from "@/components/dealer/Map/DealerMapAllLazy"

import TextBlock, {
  TextBlockButtons,
  TextBlockCopy,
  TextBlockEyebrow,
  TextBlockHeading,
} from "../../TextBlock"
import ProFormLazy from "./ProFormLazy"

export default function ProFormWrapper({
  popup,
  allDealers,
  image,
  phone,
}: {
  popup: boolean
  allDealers: GetAllDealersReturnType[]
  image?: React.ReactNode
  phone?: string
}) {
  return (
    <div
      className={cn(
        popup && "overflow-y-auto p-1 md:p-2 max-h-[92vh] sm:max-h-[90vh]"
      )}
    >
      <TextBlock>
        <TextBlockEyebrow>Become a Pro</TextBlockEyebrow>
        <TextBlockHeading level={4}>
          Join {allDealers.length}+ Other Pros
        </TextBlockHeading>
        <TextBlockCopy className="max-w-[400px] text-xs md:text-sm">
          To receive more information, please fill out the form below or call
          us.
        </TextBlockCopy>
        <TextBlockButtons>
          {phone !== undefined ? (
            <Button className="hidden sm:flex" asChild variant={"outline"}>
              <a href={`tel:${phone}`}>
                <Badge variant={"default"} className="mr-2">
                  Call Now
                </Badge>
                {formatPhoneNumber(phone)}
              </a>
            </Button>
          ) : (
            <Button variant={"outline"} asChild>
              <a href={`tel:${SiteConfig.corporate.phone}`}>
                <Badge variant={"default"} className="mr-2">
                  Call Now
                </Badge>

                {formatPhoneNumber(SiteConfig.corporate.phone)}
              </a>
            </Button>
          )}
        </TextBlockButtons>
      </TextBlock>
      <div className={"grid grid-cols-5 lg:grid-cols-10 gap-16 md:8 mt-10"}>
        <div className="col-span-5 ">
          <ProFormLazy />
        </div>
        <div className={cn("col-span-5", popup && 'hidden lg:block')}>
          {image ?? (
            <DealerMapAllLazy
              allDealers={allDealers}
              selectedDealer={null}
              setSelectedDealer={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  )
}
