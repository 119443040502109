import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "stroke-1 inline-flex items-center justify-center rounded-sm text-sm font-copy font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-carbon-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-carbon-950 dark:focus-visible:ring-carbon-300",
  {
    variants: {
      variant: {
        default:
          "bg-carbon-500 text-carbon-50 hover:bg-carbon-900 dark:bg-carbon-50 dark:text-carbon-900 dark:hover:bg-carbon-50/90",
        flame:
          "bg-flame-500 text-carbon-50 hover:bg-flame-500/90 dark:bg-flame-900 dark:text-carbon-50 dark:hover:bg-flame-900/90",
        outline:
          "text-carbon-500 border border-carbon-100 bg-white hover:bg-carbon-100 hover:text-carbon-900 dark:border-carbon-800 dark:bg-carbon-950 dark:hover:bg-carbon-800 dark:hover:text-carbon-50",
        "outline-active":
          "text-carbon-500 border border-carbon-200 bg-white hover:bg-carbon-100 hover:text-carbon-900 dark:border-carbon-800 dark:bg-carbon-950 dark:hover:bg-carbon-800 dark:hover:text-carbon-50",
        "outline-inactive":
          "text-carbon-300/80 border border-carbon-100 bg-white hover:bg-carbon-50 hover:text-carbon-700 dark:border-carbon-800 dark:bg-carbon-950 dark:hover:bg-carbon-800 dark:hover:text-carbon-50",
        secondary: "bg-carbon-100 text-carbon-900 hover:bg-carbon-100/80",
        "secondary-dark": "bg-carbon-600 text-carbon-50 hover:bg-carbon-600/80",
        ghost:
          "hover:bg-carbon-100 hover:text-carbon-900 dark:hover:bg-carbon-800 dark:hover:text-carbon-50",
        link: "text-carbon-900 underline-offset-4 hover:underline dark:text-carbon-50",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-sm px-3",
        lg: "h-11 rounded-sm px-8",
        icon: "h-10 w-10",
        icon_sm: "h-9 w-9",
        call_now: "h-10"
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
